<template>
  <div>
    <v-btn depressed @click="callAction" width="100%" v-bind="vBtnAttr">
      <span>{{ label | t }}</span>
    </v-btn>
  </div>
</template>

<script lang="babel" type="text/babel">

export default {
  props: {
    label: String,
    action: Object,
    defaultKey: String,
    cols: {
      type: Object,
      default: () => ({
        cols: 12,
      })
    },
  },
  data: () => ({
    vBtnAttr : {}
  }),
  async created() {
    await this.__init();
  },
  computed: {
    actionKey() {
      if(!this.action) return null
      return this.action.key
    },
    actionHandler() {
      if(!this.action) return null
      if(typeof this.action.handler != 'function') return null
      return this.action.handler
    },
    // isSubmit() {
    //   return this.actionKey == 'submit' ? {type : 'submit'} : {}
    // },
    // isReset() {
    //   return this.actionKey == 'reset' ? {type : 'reset'} : {}
    // },
    // vBtnAttr() {
    //   const attr = {
    //     ...this.$attrs,
    //   }
    //   if(this.isSubmit) attr.type = 'submit'
    //   return attr
    // },
  },
  methods: {
    __init() {
      let attr = {
          ...this.$attrs,
          ...this.witchType(this.actionKey)
      }
      
      this.setBtnAttr(attr) ;
    },
    setBtnAttr (attr) {
      this.vBtnAttr = { ...this.vBtnAttr, ...attr};
    },
    witchType(actionKey) {
      switch (actionKey) {
        case 'submit':
          return {type : 'submit'}
          break;
        case 'reset':
          return {type : 'reset'}
          break;
        default:
          return {};
          break;
      }

    },

    callAction() {
      if(!this.action) return

      // 檢查是否有動態參數
      if(this.action.dynamicAttr) 
        this.setBtnAttr(this.action.dynamicAttr)


      if(this.actionKey == 'back') {
        this.$router.go(-1)
      }

      if(!this.actionHandler) return
          this.actionHandler()
      // switch (this.actionKey) {
      //   case 'submit':
      //     // if(!this.action.doSubmit) return
      //     // console.log('is submit')
      //     break;
      //   case 'click':
      //     // 增加click事件
      //     if(!this.actionHandler) return
      //     this.actionHandler()
      //     break;
      //   case 'back':
      //     this.$router.go(-1)
      //     break;
      //   case 'reset':
      //     // if(!this.actionHandler) return
      //     // this.actionHandler()
      //     break;
      //   default:
      //     break;
      // }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
