export default () => {
  return {
    namespaced: true,
    state: () => ({
      valid: false,
      form: null,
      control: {},
      isSubmit: false,
      otherValidate: {
        photo: {

        }
      }
    }),
    mutations: {
      // @ts-ignore
      setValid: (state, data) => {
        // @ts-ignore
        state.valid = data
      },
      setOtherValidate: (state, { key, value }) => {
        // @ts-ignore
        state.otherValidate[key] = value
      },
      setForm: (state, data) => {
        // @ts-ignore
        state.form = data
      },
    },
    actions: {
      // @ts-ignore
      setValid: (context, data) => {
        context.commit('setValid', data)
      },
      setOtherValidate: (context, data) => {
        context.commit('setOtherValidate', data)
      },
      setForm: (context, data) => {
        context.commit('setForm', data)
      }
    },
    getters: {
      getValid: (state) => {    
        
        if (!state.valid) return false

        // 額外驗證規則, 只要一個沒過就失敗
        for (const key in state.otherValidate) {
          if (!state.otherValidate[key].has) return false
        }
    
        return true
      },
      getOtherValueErrorMessages: (state) => {
        for (const key in state.otherValidate) {
          const res = state.otherValidate[key]
          if (!res.has) return res.message
        }
      },
      getValidateMessage: (state, getters) => {
        let message = '';
        // 檢查
        for (const input of state.form.inputs) {
          if (!input.valid) {
            message = input.errorBucket[0]
            return message;
          }
        }
        return getters.getOtherValueErrorMessages
      },
      getControl: (state) => state.control,
      getForm: (state) => state.form,
    },
  }
}